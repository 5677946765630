import React, { useState, useEffect, useRef } from "react";
import "./Gallery.css";

const images = [
  { src: "/images/gallery/Sojol Rana.jpg", category: "Me", caption: "Sojol Rana" },
  { src: "/images/gallery/Sojol Rana at in front of Dept. of CSE DUET.JPG", category: "Me", caption: "Sojol Rana at in front of Dept. of CSE DUET" },
  { src: "/images/gallery/Sojol Rana at Kanaya.jpg", category: "Me", caption: "Sojol Rana at Kanaya" },
  { src: "/images/gallery/Sojol Rana at Restaurants.JPG", category: "Me", caption: "Sojol Rana at Restaurants" },
  { src: "/images/gallery/Sojol Rana at Tanguar Haor.jpg", category: "Me", caption: "Sojol Rana at Tanguar Haor" },
  { src: "/images/gallery/Terakota of Bagha Shahi Mosque.jpg", category: "Archaeology", caption: "Terakota of Bagha Shahi Mosque" },
  { src: "/images/gallery/Inside view of Bagha Shahi Mosque.jpg", category: "Archaeology", caption: "Inside view of Bagha Shahi Mosque" },
];

const Gallery = () => {
  const [filter, setFilter] = useState("All");
  const [modal, setModal] = useState({ isOpen: false, currentIndex: 0 });

  const categories = ["All", ...new Set(images.map((img) => img.category))];
  const filteredImages =
    filter === "All" ? images : images.filter((img) => img.category === filter);

  const openModal = (index) => setModal({ isOpen: true, currentIndex: index });
  const closeModal = () => setModal({ isOpen: false, currentIndex: 0 });

  const nextImage = () =>
    setModal((prev) => ({
      ...prev,
      currentIndex: (prev.currentIndex + 1) % filteredImages.length,
    }));

  const prevImage = () =>
    setModal((prev) => ({
      ...prev,
      currentIndex:
        (prev.currentIndex - 1 + filteredImages.length) % filteredImages.length,
    }));

  return (
    <div className="gallery-container">
      {/* Filter Buttons */}
      <div className="filter-container">
        {categories.map((category) => (
          <button
            key={category}
            className={`filter-button ${filter === category ? "active" : ""}`}
            onClick={() => setFilter(category)}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Gallery Grid */}
      <div className="grid">
        {filteredImages.map((img, index) => (
          <LazyImage
            key={`${img.src}-${index}`}
            src={img.src}
            alt={img.caption}
            caption={img.caption}
            onClick={() => openModal(index)}
          />
        ))}
      </div>

      {/* Modal */}
      {modal.isOpen && (
        <div className="modal">
          <button className="close-button" onClick={closeModal}>
            ✕
          </button>
          <button className="prev-button" onClick={prevImage}>
            ◀
          </button>
          <img
            src={filteredImages[modal.currentIndex].src}
            alt={filteredImages[modal.currentIndex].caption}
            className="modal-image"
          />
          <button className="next-button" onClick={nextImage}>
            ▶
          </button>
          <p className="caption">
            {filteredImages[modal.currentIndex].caption}
          </p>
        </div>
      )}
    </div>
  );
};

const LazyImage = ({ src, alt, caption, onClick }) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          imgRef.current.src = src;
        }
      },
      { threshold: 0.1 }
    );

    if (imgRef.current) observer.observe(imgRef.current);

    return () => observer.disconnect();
  }, [src]);

  return (
    <div className="image-box" onClick={onClick}>
      {!loaded && <div className="spinner"></div>}
      <img
        ref={imgRef}
        alt={alt}
        className={`image ${loaded ? "fade-in" : ""}`}
        onLoad={() => setLoaded(true)}
        onError={() => console.error(`Failed to load image: ${src}`)}
      />
      {loaded && <p className="caption">{caption}</p>}
    </div>
  );
};


export default Gallery;